import React, { useContext } from 'react';
import './RadioSelect.css';
// import {Col, Input, FormGroup , Label} from 'reactstrap';
import {
	Grid,
	Radio,
	FormControl,
	FormControlLabel,
	RadioGroup,
} from '@material-ui/core';
import RoiContext from '../../../../../../context/RoiContext';

const RadioSelect = () => {
	const { formValues, setValues } = useContext(RoiContext);

	return (
		<Grid container direction='column'>
			<div className='radio-row'>
				<RadioGroup
					row
					aria-label='time'
					name='radio2'
					onChange={({ target: { value } }) =>
						setValues({ timePeriod: parseInt(value) })
					}>
					<FormControlLabel
						type='radio'
						value={1}
						name='radio2'
						control={<Radio />}
						label='Annual ROI'
						checked={formValues.timePeriod === 1}
					/>
					<FormControlLabel
						type='radio'
						value={12}
						name='radio2'
						control={<Radio />}
						label='Monthly ROI'
						checked={formValues.timePeriod === 12}
					/>
				</RadioGroup>
			</div>
		</Grid>
	);
};

export default RadioSelect;
