import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import './FormField.css';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import RoiContext from '../../../../../../context/RoiContext';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiFormLabel-root': {
			fontSize: '1.4rem',
			[theme.breakpoints.down('xs')]: {
				fontSize: '1.2rem',
			},
		},
		'& .MuiInput-formControl': {
			marginTop: '35px',
		},
	},
}));

const FormField = ({ formId, label, min = 0, max, append, step = '1' }) => {
	const { formValues, setValues } = useContext(RoiContext);
	const classes = useStyles();

	function checkNaN(value) {
		return isNaN(value) ? 0 : Number(value);
	}

	return (
		<Grid item lg={6} xs={12}>
			<TextField
				id='filled-name'
				type='number'
				fullWidth
				label={label}
				className={classes.root}
				value={
					['avgWage', 'avgFuelPrice'].includes(formId)
						? parseFloat(formValues[formId]).toFixed(2)
						: parseFloat(formValues[formId])
				}
				// InputLabelProps={{ shrink: false }}
				inputProps={{ step, min, max }}
				onChange={({ target: { value } }) =>
					setValues({
						[formId]: checkNaN(value),
					})
				}
				// variant='filled'
			/>
		</Grid>
	);
};

export default FormField;
